
import * as uploadcareRuntime$9y2IwVL4mE from '/app/node_modules/.pnpm/@nuxt+image@1.4.0_@planetscale+database@1.16.0_ioredis@5.3.2_rollup@4.13.0/node_modules/@nuxt/image/dist/runtime/providers/uploadcare'
import * as directusRuntime$rs1aYapiXP from '/app/node_modules/.pnpm/@nuxt+image@1.4.0_@planetscale+database@1.16.0_ioredis@5.3.2_rollup@4.13.0/node_modules/@nuxt/image/dist/runtime/providers/directus'
import * as ipxRuntime$gFayFIe0Th from '/app/node_modules/.pnpm/@nuxt+image@1.4.0_@planetscale+database@1.16.0_ioredis@5.3.2_rollup@4.13.0/node_modules/@nuxt/image/dist/runtime/providers/ipx'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "ipx",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp",
    "webp"
  ]
}

imageOptions.providers = {
  ['uploadcare']: { provider: uploadcareRuntime$9y2IwVL4mE, defaults: {"cdnURL":"https://ucarecdn.com/"} },
  ['directus']: { provider: directusRuntime$rs1aYapiXP, defaults: {"baseURL":"https://directus-staging-mhgotunjaq-an.a.run.app/assets/"} },
  ['ipx']: { provider: ipxRuntime$gFayFIe0Th, defaults: {} }
}
        